import path from 'path';

import NextI18Next from 'next-i18next';
import translation from '@sizeid/shared/lib/translation';

import * as localeSubpaths from './localeSubpaths';

const { ...otherLanguages } = translation.languages;

const i18nClass: NextI18Next = new NextI18Next({
  defaultLanguage: translation.defaultLocale,
  otherLanguages: Object.keys(otherLanguages),
  localePath: path.resolve('./public/static/locales'),
  browserLanguageDetection: true,
  localeSubpaths: localeSubpaths as Record<string, string>,
  detection: {
    caches: ['cookie'],
    cookieSameSite: 'strict',
    lookupCookie: 'sizeid-language',
    order: ['cookie', 'header', 'querystring'],
  },
});

i18nClass.i18n.languages = Object.keys(otherLanguages);

export const { appWithTranslation } = i18nClass;
export const { i18n } = i18nClass;

export const { useTranslation } = i18nClass;
export const { withTranslation } = i18nClass;

export const { Router } = i18nClass;
