import { DefaultThemeType } from 'themes/types';

export const BASE_TITLE = 'SizeID';
export const BODY_ID = '__sizeid-body';

export const BUSINESS_THEME = 'business' as const;
export const DEFAULT_THEME = 'default' as const;
export const INTEGRATION_THEME = 'integration' as const;
export type { Theme } from 'themes/types';
export type Themes = Record<
  typeof BUSINESS_THEME | typeof DEFAULT_THEME | typeof INTEGRATION_THEME,
  DefaultThemeType
>;
