import { merge } from 'lodash';
import mutableGlobalTheme from '@sizeid/commons/themes/global';
import { INDEX } from '@sizeid/shared/constants/routes';

import { DEFAULT_THEME } from 'constants/index';

import { DefaultThemeType } from './types';

const globalTheme = merge({}, mutableGlobalTheme);

const pageHeader = {
  width: '100%',
  height: 80,
  bigHeight: 100,
};

const defaultTheme: DefaultThemeType = merge(globalTheme, {
  outlined: {
    border: `1px solid ${globalTheme.colors.black.light[300]}`,
  },
  sizes: {
    ...globalTheme.sizes,
    pageHeader,
    pageContent: {
      topPadding: 40,
      bottomPadding: 120,
      mobileTopPadding: 20,
      mobileBottomPadding: 40,
    },
    profileImage: {
      small: '60px',
      medium: '80px',
      large: '100px',
    },
  },
  text: {
    mobileBaseSize: 14,
    paragraph: {
      color: globalTheme.colors.black.light[400],
    },
    headings: {
      common: {
        color: globalTheme.colors.black.default,
        fontWeight: 500,
      },
      h1: {
        fontSize: '54px',
        lineHeight: '1.1296',
        mobileFontSize: '24px',
      },
      h2: {
        fontSize: '44px',
        lineHeight: '1.1364',
        mobileFontSize: '24px',
      },
      h3: {
        fontSize: '24px',
        lineHeight: '1.1667',
        mobileFontSize: '18px',
      },
      h4: {
        fontSize: '18px',
        lineHeight: '1.1667',
        mobileFontSize: '18px',
      },
    },
  },
  breakpoints: {
    ...globalTheme.breakpoints,
    smPlus: `${parseInt(globalTheme.breakpoints.sm, 10) + 1}px`,
    mdPlus: `${parseInt(globalTheme.breakpoints.md, 10) + 1}px`,
    lgPlus: `${parseInt(globalTheme.breakpoints.lg, 10) + 1}px`,
    companyHistory: '599px',
    companyHistoryPlus: '600px',
  },
  backgrounds: {
    header: globalTheme.colors.red.default,
    graySection: globalTheme.colors.black.light[50],
    grayWave: 'grayWave',
  },
  navigation: {
    type: DEFAULT_THEME,
    anchorMargin: 'bottom',
    flexDirection: 'row-reverse',
  },
  logo: {
    image: 'sizeid',
    width: {
      normal: `${pageHeader.height}px`,
      big: `${pageHeader.bigHeight}px`,
    },
    height: {
      normal: `${pageHeader.height}px`,
      big: `${pageHeader.bigHeight}px`,
    },
    href: {
      page: INDEX.page,
      route: INDEX.route,
    },
  },
});

export default defaultTheme;
